import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Grid } from '@material-ui/core';
import Link from '../components/Link';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    backgroundColor: '#F8F8F8',
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: 20,
    paddingRight: 20
  },
  image: {
    width: 60,
    display: 'inline'
  },
  tituloFooter: {
    marginBottom: 20
  },
  itemFooter: {
    marginBottom: 15
  },
  final: {
    backgroundColor: 'black',
    paddingTop: 5,
    paddingBottom: 5,
    color: 'white'
  }
}));


export default function Footer(props) {
  const classes = useStyles();

  return (

    <React.Fragment>

      <div className={classes.footer}>
        <Container maxWidth='lg'>

          <Grid container direction='row' justify='center' alignItems='flex-start' spacing={4} >
            <Grid item xs={12} md={3} >
              <div align='center'>
                <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img className={classes.image} src='/static/images/logoColor.png' />
                </Link>
                <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography conponet='span' variant='h6'>
                    VELVET CONSULTORES
                </Typography>
                </Link>
              </div>
              <br />
              <a href='tel:961970025' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography align='center'>
                  (51) 961 970 025
              </Typography>
              </a>
              <br />
              <a href='mailto:ventas@corporacionvelvet.com' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography align='center'>
                  info@corporacionvelvet.com
              </Typography>
              </a>

            </Grid>
            <Grid item xs={12} md={3} >
              <Typography variant='h6' className={classes.tituloFooter}>
                Servicios Empresa
              </Typography>
              <Link to='/seleccion-y-headhunting' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Selección y Headhunting
              </Typography>
              </Link>
              <Link to='/asesoria-legal' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Asesoría Legal - Laboral
              </Typography>
              </Link>
              <Link to='/formacion-del-talento' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Formación del Talento
              </Typography>
              </Link>
              <Link to='/desarrollo-e-integracion-organizacional' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Desarrollo e Integración Organizacional
              </Typography>
              </Link>
              <Link to='/administracion-de-personal' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Administración de Personal
              </Typography>
              </Link>
              <Link to='/salud-mental' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Programa de Salud Mental
              </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={3} >
              <Typography variant='h6' className={classes.tituloFooter}>
                Servicios Persona
              </Typography>
              <Link to='/empleabilidad-y-desarrollo-profesional' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body2' className={classes.itemFooter}>
                  Empleabilidad y Desarrollo Profesional
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={2} >
              <Typography variant='h6' align='center' className={classes.tituloFooter}>
                Redes Sociales
              </Typography>
              <div align='center'>
                <a href='https://wa.me/51932437305' style={{ textDecoration: 'none', color: 'inherit' }} target={"_blank"}>
                  <IconButton aria-label="WhatsApp" color="inherit" >
                    <WhatsAppIcon />
                  </IconButton>
                </a>
                <a href='https://www.facebook.com/VelvetConsultores' style={{ textDecoration: 'none', color: 'inherit' }} target={"_blank"}>
                  <IconButton aria-label="Facebook" color="inherit" >
                    <FacebookIcon />
                  </IconButton>
                </a>
                <a href='https://www.instagram.com/VELVETCONSULTORESPERU/' style={{ textDecoration: 'none', color: 'inherit' }} target={"_blank"}>
                  <IconButton aria-label="Instagram" color="inherit">
                    <InstagramIcon />
                  </IconButton>
                </a>
              </div>
              <br />
            </Grid>

          </Grid>
        </Container>
      </div>
      <div align='center' className={classes.final}>
        <Typography variant='caption'>
          Copyright © 2020 Corporación Velvet. Todos los derechos reservados.
      </Typography>
      </div>
    </React.Fragment>
  );
}
