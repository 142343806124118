import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '../components/Link';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';

import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white'
  },
  title: {
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    color: 'white',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    top: 0,
    bottom: 'auto'
  },
  white: {
    color: 'white'
  },
  image: {
    width: 45,
    marginRight: 15
  },
  button: {
    color: 'white',
    padding: '0px 20px',
    margin: '0px 10px'
  },
  link: {
    margin: theme.spacing(2, 1.5),
    padding: 10,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
    target: window ? window() : undefined,
  });

  //  const trigger2 = useScrollTrigger({
  //    disableHysteresis: true,
  //    threshold: 70,
  //    target: window ? window() : undefined,
  //  });

  const topBar = React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    position: 'fixed',
    color: trigger ? 'primary' : 'transparent'
  })

  //  const topBar2 = React.cloneElement(children, {
  //    elevation: trigger ? 4 : 0,
  //    position: 'fixed',
  //    color: 'primary',
  //  })

  return (
    //    <React.Fragment>
    //      {trigger &&
    //        <Slide appear={true} direction="down" in={trigger} timeout={{ enter: 1000, exit: 100 }} >
    //          {topBar2}
    //        </Slide>
    //      }
    //      {!trigger2 && topBar}
    //    </React.Fragment>
    <React.Fragment>
      {topBar}
    </React.Fragment>

  )

}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function PrimarySearchAppBar(props) {

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const classes = useStyles();

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const [openPersonas, setOpenPersonas] = React.useState(false);
  const [openEmpresas, setOpenEmpresas] = React.useState(false);

  const handleClickPersonas = () => {
    setOpenPersonas(!openPersonas);
  };

  const handleClickEmpresas = () => {
    setOpenEmpresas(!openEmpresas);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };


  const [anchorElMenu2, setAnchorElMenu2] = React.useState(null);

  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorElMenu2(null);
  };


  return (
    <div className={classes.grow}>
      <ElevationScroll {...props}>
        <AppBar color='secondary'>
          <Container maxWidth='lg'>

            <Toolbar color='white' className={classes.toolbarTitle}>
              <div className={classes.sectionMobile}>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
              </div>
              <div className={classes.sectionDesktop}>

                <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img className={classes.image} src='/static/images/logo.png' />
                </Link>
              </div>
              <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography className={classes.title} variant="h6" noWrap styled={{ color: 'white' }}>
                  VELVET
              </Typography>
              </Link>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <nav>
                  <Link variant="button" color="textPrimary" to="/" className={classes.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    Inicio
                  </Link>
                  <Link
                    className={classes.link}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickMenu}
                    variant="button"
                    component="button"
                    color="textPrimary"
                    href="#"
                    className={classes.link}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    EMPRESA
                 </Link>
                  <Menu
                    anchorEl={anchorElMenu}
                    keepMounted
                    open={Boolean(anchorElMenu)}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Link to='/seleccion-y-headhunting' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Selección y Headhanting </MenuItem></Link>
                    <Link to='/administracion-de-personal' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Administración de Personal </MenuItem></Link>
                    {/* <Link to='/asesoria-contable-tributaria' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Asesoría Contable y Tributaria</MenuItem></Link>*/}
                    <Link to='/formacion-del-talento' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Formación del Talento</MenuItem></Link>
                    <Link to='/desarrollo-e-integracion-organizacional' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Desarrollo e Integración Organizacional</MenuItem></Link>
                    <Link to='/asesoria-legal' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Asesoría Legal - Laboral</MenuItem></Link>
                    <Link to='/salud-mental' style={{ textDecoration: 'none', color: 'inherit' }}><MenuItem onClick={handleCloseMenu}> Programa de Salud Mental</MenuItem></Link>
                  </Menu>
                  <Link
                    className={classes.link}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickMenu2}
                    variant="button"
                    component="button"
                    color="textPrimary"
                    to="#"
                    className={classes.link}
                    style={{ textDecoration: 'none', color: 'inherit' }}

                  >
                    PERSONAS
                 </Link>
                  <Menu
                    anchorEl={anchorElMenu2}
                    keepMounted
                    open={Boolean(anchorElMenu2)}
                    onClose={handleCloseMenu2}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem onClick={handleCloseMenu2}><Link to='/empleabilidad-y-desarrollo-profesional' style={{ textDecoration: 'none', color: 'inherit' }}>Empleabilidad y desarrollo profesional</Link></MenuItem>
                  </Menu>

                  <a href='https://aula.corporacionvelvet.com' style={{ textDecoration: 'none', color: 'white' }}>
                    <Button variant='contained' style={{ borderRadius: 8, backgroundColor: "#EC2E5D", paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 4, marginLeft: 5, marginRight: 5 }}>
                      <Typography style={{ color: 'white' }}>
                        Aula Virtual
                    </Typography>
                    </Button>
                  </a>
                </nav>
              </div>
              <a href='https://wa.me/51932437305' style={{ textDecoration: 'none', color: 'white' }} target={"_blank"}>
                <IconButton aria-label="show 4 new mails" color="inherit" >
                  <WhatsAppIcon />
                </IconButton>
              </a>
              <a href='https://www.facebook.com/VelvetConsultores' style={{ textDecoration: 'none', color: 'white' }} target={"_blank"}>
                <IconButton aria-label="show 4 new mails" color="inherit" >
                  <FacebookIcon />
                </IconButton>
              </a>
              <a href='https://www.instagram.com/VELVETCONSULTORESPERU/' style={{ textDecoration: 'none', color: 'white' }} target={"_blank"}>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <InstagramIcon />
                </IconButton>
              </a>

            </Toolbar>
            <Drawer anchor={'left'} open={openDrawer} onClose={toggleDrawer(false)}>
              <div className={classes.list} >

                <List
                  component="nav"

                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Velvet Consultores
                  </ListSubheader>
                  }
                  className={classes.root}
                >
                  <ListItem button >
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Inicio" />
                    </Link>
                  </ListItem>
                  <ListItem button onClick={handleClickEmpresas}>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Empresas" />
                    {openEmpresas ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openEmpresas} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                      <Link to='/seleccion-y-headhunting' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Selección y Headhunting" />
                        </ListItem>
                      </Link>

                      <Link to='/administracion-de-personal' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Administración de Personal" />
                        </ListItem>
                      </Link>

                      <Link to='/formacion-del-talento' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Formación del Talento" />
                        </ListItem>
                      </Link>

                      <Link to='/desarrollo-e-integracion-organizacional' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Desarrollo e Integración Organizacional" />
                        </ListItem>
                      </Link>

                      <Link to='/asesoria-legal' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Asesoría Legal - Laboral" />
                        </ListItem>
                      </Link>
                      <Link to='/salud-mental' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Programa de Salud Mental" />
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                  <ListItem button onClick={handleClickPersonas}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Personas" />
                    {openPersonas ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openPersonas} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link to='/empleabilidad-y-desarrollo-profesional' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Empleabilidad y Desarrollo Profesional" />
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                  <ListItem button >
                    <a href='https://aula.corporacionvelvet.com/' style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Aula Virtual" />
                    </a>
                  </ListItem>
                </List>
              </div>
            </Drawer>

          </Container>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}


